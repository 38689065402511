<template>
  <div class="advertising-manage-shop-page">
    <div class="container">
      <h2 class="advertising-manage-shop-page__title">
        Управление рекламой
      </h2>

      <div class="advertising-manage-shop-page__controls">
        <SearchInput
          :emit-input-on-change="false"
          :value="params.name"
          :icon="iconMagnifier"
          class="advertising-manage-shop-search"
          @input="inputSearchTextDebounced"
        />

        <ChannelTags
          :value="params.channel_id"
          class="filters__channel-tags"
          :channels="channelListCurrentUser"
          @input="changeValue({ channel_id: $event })"
        />
      </div>

      <div>
        <SpinnerContainer :is-loading="loadingTableState">
          <DynamicTableHeightWrapper
            v-if="!appLoading"
            :key="reloadTable"
            :paginated-table="true"
          >
            <PaginatedTable
              ref="table"
              :columns="tableColumns"
              :data="campaignsList"
              :items-total="itemsCampaignsTotal"
              :current-page="params.page"
              :color-function="colorFunction"
              :per-page="params.per_page"
              :fill-container="true"
              :initial-sorting="params.sorting"
              :multi-sortable="true"
              font-size="S"
              @fetch="handlePaginationChange"
            />
          </DynamicTableHeightWrapper>
        </SpinnerContainer>
      </div>
    </div>
  </div>
</template>

<script>
  import PropsDependentPage from 'pages/mixins/PropsDependentPage';
  import {mapActions, mapGetters} from 'vuex';
  import {
    FETCH_CAMPAIGNS_CURRENT_USER,
    FETCH_PLACEMENT_TYPES,
    FETCH_CHANNELS_CURRENT_USER,
    FETCH_METRICS_FOR_CAMPAIGNS
  } from 'store/modules/seller/campaignsSeller';
  import DynamicTableHeightWrapper from 'components/Tables/TableCells/DynamicTableHeightWrapper.vue';
  import {SpinnerContainer, PaginatedTable, SearchInput} from '@marilyn/marilyn-ui';
  import {getSellerCampaignsTableColumns, getFindSellerCampaignsStatusById} from 'utils/seller/setllerCampaingsTableUtils';
  import _ from 'lodash';
  import iconMagnifier from 'assets/icons/search.svg';
  import StorageAdapter from 'adapters/StorageAdapter';
  import UrlAdapter from 'adapters/UrlAdapter';
  import ChannelTags from 'pages/statistics/components/ChannelTags.vue';
  import {converterArrayWrapper, numberConverters} from 'adapters/converters';
  import MModalMixin from 'legacy/modules/common/components/MModal/MModalMixin';
  import SellerSettingStrategyModal from 'components/seller/campaings/SellerSettingStrategyModal.vue';
  import appEventBus from 'utils/appEventBus';
  import {SELLER_META_HAS_SHOW_MODAL_CREATE_FIRST_STRATEGY} from 'utils/seller/sellerMetaUtils';
  import successIcon from 'assets/icons/success-icon.svg';
  import {SET_META_USER} from 'store/modules/seller/userSeller';
  import {openSellerOnboarding} from 'onboarding/utils/sellerOnboardingUtils';
  import lodashKeyBy from 'lodash.keyby';
  import {bidManagerProgressMetricsService} from 'services';

  function colorFunction (tableRow) {
    const isActiveCampaign = Boolean(getFindSellerCampaignsStatusById(tableRow.status));

    if (isActiveCampaign) {
      return {};
    }

    return {background: '#F5F5F5', color: '#8D8D8D', linkColor: '#8D8D8D'};
  }

  export default {
    name: 'SellerAdvertisingManagerPage',
    components: {
      ChannelTags,
      DynamicTableHeightWrapper,
      SpinnerContainer,
      PaginatedTable,
      SearchInput
    },
    mixins: [PropsDependentPage, MModalMixin],
    data () {
      return {
        modalComponent: SellerSettingStrategyModal,
        modalProps: {},
        reloadTable: 0,
        modalEventListeners: {
          'success-save': this.handleSettingStrategyModalSuccessSave,
          'success-create': this.handleSettingStrategyModalSuccessCreate,
          cancel: this.handleSettingStrategyModalCancel
        },

        iconMagnifier,

        tableIsLoading: false,
        onboardingAlreadyBeenOpened: false,

        storageAdapter: null,
        urlAdapter: null,

        params: {
          page: 1,
          name: null,
          per_page: 10,
          channel_id: []
        }
      };
    },
    computed: {
      ...mapGetters('sellerCampaigns', ['campaignsList', 'itemsCampaignsTotal', 'channelListCurrentUser']),
      ...mapGetters('sellerCommon', ['appLoading']),
      ...mapGetters('sellerUser', ['isNeedShowCreateFirstStrategyModal', 'isNeedShowOnboardingCampaignListFirst']),
      loadingTableState () {
        return this.appLoading || this.tableIsLoading;
      },
      tableColumns () {
        return getSellerCampaignsTableColumns({
          campaignsList: this.campaignsList,
          availableProgressMetricsMap: this.availableProgressMetricsMap
        });
      },
      availableProgressMetricsMap () {
        return lodashKeyBy(bidManagerProgressMetricsService.metricsMeta(), 'id');
      }
    },
    watch: {
      campaignsList: {
        handler () {
          this.updateTable();
        },
        deep: true
      }
    },
    created () {
      appEventBus.$on('SHOW_STRATEGY_SETTING_MODAL', this.showStrategySettingPopup);
      this.$on('hook:destroyed', () => {
        appEventBus.$off('SHOW_STRATEGY_SETTING_MODAL', this.showStrategySettingPopup);
      });
      this.urlAdapterInit();
    },
    methods: {
      colorFunction,
      ...mapActions('sellerCampaigns', {
        fetchCampaignsCurrentUser: FETCH_CAMPAIGNS_CURRENT_USER,
        fetchMetricsForCampaigns: FETCH_METRICS_FOR_CAMPAIGNS,
        fetchPlacementTypes: FETCH_PLACEMENT_TYPES,
        fetchChannelsCurrentUser: FETCH_CHANNELS_CURRENT_USER
      }),
      ...mapActions('sellerUser', {
        setMetaUser: SET_META_USER
      }),
      handleSettingStrategyModalCancel () {
        this.hidePopup();
      },
      handleSettingStrategyModalSuccessSave (notifyData) {
        this.$notify({
          type: 'success',
          ...notifyData
        });

        this.fetchCampaignsList();
        this.hidePopup();
      },
      async setMetaShowCreateFirstStrategy () {
        await this.setMetaUser({[SELLER_META_HAS_SHOW_MODAL_CREATE_FIRST_STRATEGY]: true});
      },
      handleSettingStrategyModalSuccessCreate () {
        if (this.isNeedShowCreateFirstStrategyModal) {
          appEventBus.$emit('SHOW_SUCCESS_CREATE_SHOP_MODAL', {
            contentTitle: 'Ура, вы запустили первую стратегию!',
            contentText: 'Теперь ставки под контролем сервиса. Выберите стратегии для остальных рекламных кампаний или возвращайтесь в сервис через 3 дня, чтобы увидеть динамику по ДРР и заказам.',
            contentButton: 'Хорошо',
            positionButtonIsCenter: true,
            contentIcon: successIcon
          });

          this.setMetaShowCreateFirstStrategy();
        } else {
          this.$notify({
            type: 'success',
            header: 'Вы запустили стратегию',
            text: 'Теперь ставки под контролем сервиса. Через 3 дня появится первая динамика по ДРР и заказам.'
          });
        }

        this.fetchCampaignsList();
        this.hidePopup();
      },
      updateTable () {
        this.$nextTick(() => {
          // todo: пофиксить апдейт
          this.reloadTable = this.reloadTable + 1;
        });
      },
      urlAdapterInit () {
        this.urlAdapter = new UrlAdapter({
          name: {},
          channel_id: {
            alias: 'channel_id',
            converters: converterArrayWrapper(numberConverters)
          }
        });

        this.storageAdapter = new StorageAdapter('campaigns-table-storage', {
          per_page: 10
        });

        const {name, channel_id} = this.urlAdapter.load(this.params);
        const {per_page} = this.storageAdapter.load(this.params);

        this.params = {
          ...this.params,
          name,
          per_page,
          channel_id
        };
      },
      tryOpenFirstOnboarding () {
        const canOpen = !this.onboardingAlreadyBeenOpened &&
          this.campaignsList.length &&
          this.isNeedShowOnboardingCampaignListFirst &&
          this.$refs.table;

        const needCallFn = this.isNeedShowOnboardingCampaignListFirst &&
          !this.onboardingAlreadyBeenOpened &&
          this.campaignsList.length;

        if (canOpen) {
          this.onboardingAlreadyBeenOpened = true;
          openSellerOnboarding('campaign-list-first-onboarding');
        } else if (needCallFn) {
          setTimeout(this.tryOpenFirstOnboarding, 1000);
        }
      },
      async fetchInitCampaignList () {
        await this.fetchCampaignsList();
        this.tryOpenFirstOnboarding();
      },
      fetchDataAsync () {
        return [
          this.fetchInitCampaignList(),
          this.fetchMetricsForCampaigns(),
          this.fetchPlacementTypes(),
          this.fetchChannelsCurrentUser()
        ];
      },
      showStrategySettingPopup (params) {
        this.modalProps = {
          ...this.modalProps,
          ...params.modalProps
        };

        this.modalEventListeners = {
          ...this.modalEventListeners,
          ...params.modalEventListeners
        };

        this.showPopup();
      },
      async fetchCampaignsList () {
        this.tableIsLoading = true;

        await this.fetchCampaignsCurrentUser(this.params);

        this.tableIsLoading = false;
      },

      handlePaginationChange (params) {
        Object.assign(this.params, {per_page: params.perPage, page: params.page});
        this.storageAdapter.apply({per_page: params.perPage});
        this.fetchCampaignsList();
      },
      changeValue (payload) {
        this.params = {
          ...this.params,
          ...payload
        };

        this.urlAdapter.apply({name: this.params.name, channel_id: this.params.channel_id});
        this.fetchCampaignsList();
      },
      inputSearchTextDebounced: _.debounce(function (text) {
        this.changeValue({name: text});
      }, 500)
    }
  };
</script>

<style>
  .advertising-manage-shop-page__title {
    color: #242424;
    font-size: 32px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.03em;
    margin: 0 0 32px;
  }

  .advertising-manage-shop-search {
    width: 200px;
  }

  .advertising-manage-shop-page__controls {
    display: flex;
    align-items: center;

    margin-bottom: 32px;

    & > *:not(:last-child) {
      margin-right: 24px;
    }
  }
</style>
